.loader-wrapper {
  padding-top: 18%;
  vertical-align: middle;
}

.loader {
  border: 19px solid #3459a6; /* Light grey */
  border-top: 16px solid #f3f3f3; /* Blue */
  border-radius: 50%;
  width: 120px;
  margin: 0px auto;
  height: 120px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
