.footer-text {
  font-size: 17px;
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
  margin-bottom: 0px;
}

.footer-div {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .footer-text {
    font-size: 25px;
  }
}
