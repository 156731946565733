.techMain {
  margin-bottom: 0px;
  overflow: hidden;
}

.techstack-heading {
  text-align: center;
  font-size: 45px;
  font-weight: 400;
  font-family: "Google Sans Regular";
  line-height: normal;
  margin-bottom: 35px;
}

.skills-image-div {
  max-width: 100%;
  height: auto;
  margin-top: 100px;
}

.meter {
  height: 20px;
  margin-bottom: 15px;
  position: relative;
  background: #ddddee;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #aaa5ff;
  position: relative;
  overflow: hidden;
}
.skill {
  line-height: 3.5vw;
}
.skill-bars {
  font-size: 28px;
  width: 40%;
}
.skills-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}
.skills-container > * {
  flex: 1;
  margin-bottom: 30px;
}
.skills-image > img {
  margin-left: 80px;
  max-width: 90%;
  height: auto;
}

/* Media Query */

@media (max-width: 1456px) {
  .skills-bar {
    line-height: 4rem;
  }
  .skills-image {
    display: none;
    order: 2;
  }
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }
  .skills-bar {
    line-height: 3rem;
    margin: 20px;
  }
  .skills-image {
    display: none;
    order: 2;
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .techstack-heading {
    font-size: 40px;
  }
  .skills-header {
    font-size: 50px;
  }
  /* .skills-image-div > img {
    margin-top: 0px;
  } */
}
@media (max-width: 768px) {
  .techstack-heading {
    font-size: 30px;
    text-align: center;
  }
  .skills-header {
    font-size: 30px;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
  .skills-image-div > * {
    margin-top: 0px;
  }
}
