.talks-main {
  overflow: hidden;
}
.talk-header-title {
  text-align: center;
  font-size: 45px;
  font-weight: 400;
  line-height: 0px;
}

.talk-header-subtitle {
  margin-top: 40px;
  font-size: 20px;
  margin-bottom: -20px;
  text-align: center;
}
@media (max-width: 768px) {
  .talk-header-title {
    font-size: 30px;
    text-align: center;
  }
  .talk-header-subtitle {
    font-size: 15px;
    line-height: normal;
    text-align: center;
  }
}
